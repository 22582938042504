html, body {
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}
div {
  margin: 0;
  padding: 0;
}
#root {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
}

.App {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: 600px;
  margin: 0 auto;
}

.top {
  vertical-align: top;
  width: 100%;
  overflow: auto;
}

.top .logo_wrap {
  width: 100%;
  text-align: center;
  padding: 10px auto;
}
.top .logo_wrap .logo {
  background-image: url("https://cdn.0br1.io/img/logos/logo_192x192.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body_wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  height: calc(100% - 80px);
}

.info_wrap {
  width: calc(100%-40px);
  text-align: left;
  padding: 10px 20px;
  overflow: auto;
}
.info_wrap .info_text {
  overflow: auto;
  min-height: 20px;
  line-height: 20px;
  color: #222222;
  font-size: 9pt;
  font-weight: 600;
}

.search_wrap {
  width: 100%;
}
.search_wrap .input_wrap {
  height: 40px;
  width: 100%;
}
.search_wrap .input_wrap .input_search_key {
  width: calc(100% - 80px);
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid #d7d6d6;
  font-size: 12pt;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  float: left;
}
.search_wrap .input_wrap .btn_input_clear {
  width: 50px;
  float: left;
  height: 40px;
  margin-right: 10px;
  line-height: 40px;
  color: #666666;
  background-color: #d7d6d6;
  font-size: 11pt;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
.search_wrap .btn_wrap {
  margin: 0 auto;
  height: 40px;
  padding: 0;
  margin: 0px 10px 0 10px;
  color: #ffffff;
}
.search_wrap .btn_wrap .btn_search {
  float: left;
  line-height: 40px;
  width: 100%;
  height: 40px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ff6700;
}

.result_wrap {
  width: 100%;
  height: calc(100%-40px);
  padding: 20px 0;
}

.result_wrap .result_list {
  width: calc(100%-40px);
  height: 100%;
  padding: 0 20px;
  list-style: none;
}

.result_wrap .result_list .result_item {
  display: flex;
  width: 100%;
  min-height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  border-bottom: 1px solid #d7d6d6;
  margin: auto 0;
  padding: 0 10px;
  align-items: center;
}

.result_wrap .result_list .result_item .result_item_address .address {
  display: flex;
  align-items: center;
}

.result_wrap .result_list .result_item .result_item_address .address_detail {
  line-height: 20px;
  margin-bottom: 10px;
}

.spacer {
  margin: 0 auto;
}

.spacer_hotizontal_4 {
  width: 4px;
}

.spacer_vertical_4 {
  height: 4px;
}

.spacer_hotizontal_10 {
  width: 10px;
}

.spacer_vertical_10 {
  height: 10px;
}

.result_wrap .result_list .result_item .result_service_area {
  height: 100%;
  display: flex;
  align-items: center;
}

.result_wrap .result_list .result_item .service_area_result {
  height: 100%;
}

.service_result_img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.service_area_result_text {
  line-height: 20px;
  font-size: 14px;
}

.service_area_result_text .ok {
  color: #38b349;
  font-weight: 700;
}

.service_area_result_text .no {
  color: #f23b2f;
  font-weight: 400;
}

.loader_wrapper {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader_wrapper .loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #ff6700;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.empty {
  color: #222222;
  width: 100%;
  height: calc(100% - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.cs_desc {
  display: inline-flex;
  margin-top: 16px;
  color: #737373;
  line-height: 23px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}

.cs_desc .text:last-child {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .logo {
    width: 40px !important;
    height: 40px !important;
  }
  .body_wrap {
    height: calc(100% - 40px);
  }
}
